button {
    background-color: yellow;
    padding: 5px;
    border-radius: 5px;
    margin-left: 5px;
}
button:hover {
    background-color: black;
    color: yellow;
}
